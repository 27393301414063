import { useEffect, useMemo, useState, useRef, useContext } from "react";
import { createPortal } from 'react-dom'
import { Button, Modal, Select } from '@appkit4/react-components';

// import { wait } from '../../../../../../utils/CommonFunction'
import { Loading } from "../../../../../../components/Loading";
import { UploadField } from "./components/UploadField";
import { TT_TYPE, UPLOAD_TYPE } from "../../../../../../constants";
import { message, /*Message*/ } from "../../../../../../components/Message";
import { Context } from '../../../../Context'
import { getTenentList, uploadRules, uploadTT, uploadPwCTT, uploadSolution, uploadEvent, uploadIncRpt, uploadThreatActor } from "../../../../../../api";
import { useUserInfo } from "../../../../../../hooks";

import "./index.scss";

// const longMessage = new Message(0)

export const UploadModal = ({
  visible,
  onCancel,
  type,
  ttType
}) => {

  const [storedUserInfo] = useUserInfo()
  const {
    setLoading: setTableLoading,
    fetchAndSetSolutionData,
    fetchAndSetTTData,
    fetchAndSetRuleData,
    fetchAndSetEventData,
    fetchAndSetIncRptData,
    fetchAndSetThreatActorData
  } = useContext(Context)
  const [loading, setLoading] = useState(false)

  const [solutionJpFile, setSolutionJpFile] = useState(null)
  const [solutionEnFile, setSolutionEnFile] = useState(null)
  const [ttOutlineFile, setTtOutlineFile] = useState(null)
  const [ttBodyFile, setTtBodyFile] = useState(null)
  const [ruleFile, setRuleFile] = useState(null)
  const [eventFile, setEventFile] = useState(null)
  const [incRptFile, setIncRptFile] = useState(null)
  const [threatActorFile, setThreatActorFile] = useState(null)

  const solutionJpUploadEl = useRef(null)
  const solutionEnUploadEl = useRef(null)
  const ttOutlineUploadEl = useRef(null)
  const ttBodyUploadEl = useRef(null)
  const ruleUploadEl = useRef(null)
  const eventUploadEl = useRef(null)
  const incRptUploadEl = useRef(null)
  const threatActorUploadEl = useRef(null)

  const [tenentList, setTenentList] = useState([])
  const [tenent, setTenent] = useState('')

  const modalRef = useRef(null)

  const desc = useMemo(() => {
    switch (type) {
      case UPLOAD_TYPE.SOLUTION:
        return "テキストファイルをzip化したものをアップロードしてください。日本語ファイルと英語ファイルは別々に処理されます。"
      case UPLOAD_TYPE.TT:
        return "xlsxファイル又はjsonファイルをアップロードしてください。"
      case UPLOAD_TYPE.RULE:
        return "YARA-lファイルをzip化したものをアップロードしてください。"
      case UPLOAD_TYPE.EVENT:
      case UPLOAD_TYPE.INCRPT:
        return "xlsxファイルをアップロードしてください。"
      case UPLOAD_TYPE.THREAT_ACTOR:
        return "日本語のxlsxファイルををアップロードしてください。"
      default: return
    }
  }, [type])

  const title = useMemo(() => {
    switch (type) {
      case UPLOAD_TYPE.SOLUTION:
        return "対応策ファイルをアップロードする";
      case UPLOAD_TYPE.TT:
        return "Tactic and Technique ファイルをアップロードする";
      case UPLOAD_TYPE.RULE:
        return "ルールファイルをアップロードする"
      case UPLOAD_TYPE.EVENT:
        return "地政学イベントファイルをアップロードする"
      case UPLOAD_TYPE.INCRPT:
        return "インシデントレポートファイルをアップロードする"
      case UPLOAD_TYPE.THREAT_ACTOR:
        return "脅威アクターの説明ファイルをアップロードする"
      default: return
    }
  }, [type])

  const isUploadActived = useMemo(() => {
    switch (type) {
      case UPLOAD_TYPE.SOLUTION:
        return solutionJpFile || solutionEnFile
      case UPLOAD_TYPE.TT:
        return ttOutlineFile || ttBodyFile
      case UPLOAD_TYPE.RULE:
        return ruleFile && tenent
      case UPLOAD_TYPE.EVENT:
        return eventFile
      case UPLOAD_TYPE.INCRPT:
        return incRptFile
      case UPLOAD_TYPE.THREAT_ACTOR:
        return threatActorFile
      default: return
    }
  }, [ruleFile, solutionEnFile, solutionJpFile, tenent, ttBodyFile, ttOutlineFile, eventFile, incRptFile, threatActorFile, type])

  const uploadRulesAndRefresh = async () => {
    if (!tenent) return message.error('テナントを選択してください')
    if (!ruleFile) return message.error('ファイルを選択してください')
    const data = new FormData();
    data.append('name', storedUserInfo.name)
    data.append('rules', ruleFile.originFile)
    data.append('companyCode', tenent)
    setLoading(true)
    const { success } = await uploadRules(data).finally(() => setLoading(false))
    setTableLoading(true)
    fetchAndSetRuleData().finally(() => setTableLoading(false))
    return success
  }

  const uploadThreatActorAndRefresh = async () => {
    if (!threatActorFile) return message.error('ファイルを選択してください')
    const data = new FormData();
    data.append('name', storedUserInfo.name)
    data.append('ta_body', threatActorFile.originFile)
    setLoading(true)
    const { success } = await uploadThreatActor(data).finally(() => setLoading(false))
    setTableLoading(true)
    fetchAndSetThreatActorData().finally(() => setTableLoading(false))
    return success
  }

  const uploadTTAndRefresh = async () => {
    if (!ttOutlineFile && !ttBodyFile) return message.error('ファイルを選択してください')
    let fn = () => { }
    const data = new FormData()
    data.append('name', storedUserInfo.name)

    if (ttType === TT_TYPE.standard) {
      ttOutlineFile && data.append('tt_outline', ttOutlineFile.originFile)
      ttBodyFile && data.append('tt_body', ttBodyFile.originFile)
      fn = uploadTT
    }
    if (ttType === TT_TYPE.pwc) {
      ttOutlineFile && data.append('pt_outline', ttOutlineFile.originFile)
      ttBodyFile && data.append('pt_body', ttBodyFile.originFile)
      fn = uploadPwCTT
    }

    setLoading(true)
    const { success } = await fn(data).finally(() => setLoading(false))
    setTableLoading(true)
    fetchAndSetTTData().finally(() => setTableLoading(false))
    return success
  }

  const uploadSolutionAndRefresh = async () => {
    if (!solutionJpFile && !solutionEnFile) return message.error('ファイルを選択してください')
    const data = new FormData()
    data.append('name', storedUserInfo.name)
    solutionJpFile && data.append('invest_jp', solutionJpFile.originFile)
    solutionEnFile && data.append('invest_en', solutionEnFile.originFile)
    setLoading(true)
    const { success } = await uploadSolution(data).finally(() => setLoading(false))
    setTableLoading(true)
    fetchAndSetSolutionData().finally(() => setTableLoading(false))
    return success
  }

  const uploadEventAndRefresh = async () => {
    if (!eventFile) return message.error('ファイルを選択してください')
    const data = new FormData()
    data.append('name', storedUserInfo.name)
    eventFile && data.append('evt_body', eventFile.originFile)
    setLoading(true)
    const { success } = await uploadEvent(data).finally(() => setLoading(false))
    setTableLoading(true)
    fetchAndSetEventData().finally(() => setTableLoading(false))
    return success
  }

  const uploadIncRptAndRefresh = async () => {
    if (!incRptFile) return message.error('ファイルを選択してください')
    const data = new FormData()
    data.append('name', storedUserInfo.name)
    incRptFile && data.append('inct_body', incRptFile.originFile)
    setLoading(true)
    const { success } = await uploadIncRpt(data).finally(() => setLoading(false))
    setTableLoading(true)
    fetchAndSetIncRptData().finally(() => setTableLoading(false))
    return success
  }

  const upload = async () => {
    let success = false
    switch (type) {
      case UPLOAD_TYPE.SOLUTION:
        success = await uploadSolutionAndRefresh()
        break;

      case UPLOAD_TYPE.TT:
        success = await uploadTTAndRefresh()
        break;

      case UPLOAD_TYPE.RULE:
        success = await uploadRulesAndRefresh()
        break;

      case UPLOAD_TYPE.EVENT:
        success = await uploadEventAndRefresh()
        break;

      case UPLOAD_TYPE.INCRPT:
        success = await uploadIncRptAndRefresh()
        break;

      case UPLOAD_TYPE.THREAT_ACTOR:
        success = await uploadThreatActorAndRefresh()
        break;

      default: break;
    }

    success && onCancel()
  }

  const fetchAndSetTenentList = async () => {
    setLoading(true)
    const { companies, success } = await getTenentList().finally(() => setLoading(false))
    success && setTenentList(companies)
  }

  useEffect(() => {
    if (visible) {
      type === UPLOAD_TYPE.RULE && fetchAndSetTenentList()
    } else {
      setSolutionJpFile(null)
      setSolutionEnFile(null)
      setTtOutlineFile(null)
      setTtBodyFile(null)
      setRuleFile(null)
      setEventFile(null)
      setIncRptFile(null)
      setThreatActorFile(null)
      solutionEnUploadEl.current?.clearFiles()
      solutionJpUploadEl.current?.clearFiles()
      ttBodyUploadEl.current?.clearFiles()
      ttOutlineUploadEl.current?.clearFiles()
      ruleUploadEl.current?.clearFiles()
      eventUploadEl.current?.clearFiles()
      incRptUploadEl.current?.clearFiles()
      threatActorUploadEl.current?.clearFiles()
      setTenent('')
      setTenentList([])
    }
  }, [type, visible])

  return (

    <Modal
      className="file-upload-modal-wrapper"
      visible={visible}
      onCancel={onCancel}
      closable
      title={title}
      backdropClosable
      ref={modalRef}
    >
      {modalRef.current && createPortal(
        <Loading visible={loading} className="loadingMask" onClick={e => e.stopPropagation()} />,
        modalRef.current
      )}


      <div div="mcm-body">
        <div className="middle-text">
          {desc}
        </div>

        <div className="content">
          {
            type === UPLOAD_TYPE.SOLUTION &&
            <>
              <UploadField
                title="日本語ファイル"
                onFileChange={fileList => setSolutionJpFile(fileList[0])}
                ref={solutionJpUploadEl}
                acceptFileType=".zip"
              />
              <UploadField
                title="英語ファイル"
                onFileChange={fileList => setSolutionEnFile(fileList[0])}
                ref={solutionEnUploadEl}
                acceptFileType=".zip"
              />
            </>
          }
          {
            type === UPLOAD_TYPE.TT &&
            <>
              <UploadField
                title="概要説明ファイル"
                onFileChange={fileList => setTtOutlineFile(fileList[0])}
                ref={ttOutlineUploadEl}
                acceptFileType=".xlsx"
              />
              <UploadField
                title="本体ファイル"
                onFileChange={fileList => setTtBodyFile(fileList[0])}
                ref={ttBodyUploadEl}
                acceptFileType=".json"
              />
            </>
          }
          {
            type === UPLOAD_TYPE.RULE &&
            <>
              <Select
                placeholder="テナントを選択する"
                data={tenentList.map(x => ({
                  label: x.name,
                  value: x.code
                }))}
                onSelect={setTenent}
                value={tenent}
              />
              <UploadField
                ref={ruleUploadEl}
                onFileChange={fileList => setRuleFile(fileList[0])}
                acceptFileType=".zip"
              />
            </>
          }
          {
            type === UPLOAD_TYPE.EVENT &&
            <>
              <UploadField
                ref={eventUploadEl}
                onFileChange={fileList => setEventFile(fileList[0])}
                acceptFileType=".xlsx"
              />
            </>
          }
          {
            type === UPLOAD_TYPE.INCRPT &&
            <>
              <UploadField
                ref={incRptUploadEl}
                onFileChange={fileList => setIncRptFile(fileList[0])}
                acceptFileType=".xlsx"
              />
            </>
          }
          {
            type === UPLOAD_TYPE.THREAT_ACTOR &&
            <>
              <UploadField
                ref={threatActorUploadEl}
                onFileChange={fileList => setThreatActorFile(fileList[0])}
                acceptFileType=".xlsx"
              />
            </>
          }
        </div>

        <div className="button-comp" style={{ marginTop: "15px" }}>
          <Button className="button-cancel-modal" kind="secondary" onClick={onCancel} size="lg">
            キャンセル
          </Button>
          <Button className="button-do-upload" size="lg" onClick={upload} disabled={!isUploadActived}>
            アップロード
          </Button>
        </div>
      </div>

    </Modal>

  );
};

