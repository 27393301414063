import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Tab } from '@appkit4/react-components'

import { UploadHistoryTable } from './components/UploadHistoryTable';
import { Loading } from '../../components/Loading'
import { UPLOAD_TYPE } from '../../constants';
import { Context } from './Context';
import { getSolutionData, getTTData, getRuleData, getEventData, getIncRptData, getThreatActorData } from '../../api';

import "./index.scss"


export const Upload = () => {

  const [solutionTableData, setSolutionTableData] = useState([])
  const [tTTableData, setTTTableData] = useState([])
  const [ruleTableData, setRuleTableData] = useState([])
  const [eventTableData, setEventTableData] = useState([])
  const [incRptTableData, setIncRptTableData] = useState([])
  const [threatActorTableData, setThreatActorTableData] = useState([])

  const [loading, setLoading] = useState(false);
  const { current } = useRef({
    timer: ''
  })

  useEffect(() => {
    setLoading(true)
    fetchAndSetSolutionData().finally(() => setLoading(false))
    current.timer = setInterval(() => fetchAndSetSolutionData(), process.env.REACT_APP_POLLING_INTERVAL)
    return () => {
      clearInterval(current.timer)
    }
  }, [current])

  const fetchAndSetSolutionData = async () => {
    const { success, details } = await getSolutionData()
    if (success) setSolutionTableData(details)
  }

  const fetchAndSetTTData = async () => {
    const { success, details } = await getTTData()
    if (success) setTTTableData(details)
  }

  const fetchAndSetRuleData = async () => {
    const { success, details } = await getRuleData()
    if (success) setRuleTableData(details)
  }

  const fetchAndSetEventData = async () => {
    const { success, details } = await getEventData()
    if (success) setEventTableData(details)
  }

  const fetchAndSetIncRptData = async () => {
    const { success, details } = await getIncRptData()
    if (success) setIncRptTableData(details)
  }

  const fetchAndSetThreatActorData = async () => {
    const { success, details } = await getThreatActorData()
    success && setThreatActorTableData(details)
  }

  const onTabChange = async (index, value) => {
    clearInterval(current.timer)
    switch (value) {
      case UPLOAD_TYPE.SOLUTION:
        setLoading(true)
        fetchAndSetSolutionData().finally(() => setLoading(false))
        current.timer = setInterval(() => fetchAndSetSolutionData(), process.env.REACT_APP_POLLING_INTERVAL)
        break;
      case UPLOAD_TYPE.TT:
        setLoading(true)
        fetchAndSetTTData().finally(() => setLoading(false))
        current.timer = setInterval(() => fetchAndSetTTData(), process.env.REACT_APP_POLLING_INTERVAL)
        break;
      case UPLOAD_TYPE.RULE:
        setLoading(true)
        fetchAndSetRuleData().finally(() => setLoading(false))
        current.timer = setInterval(() => fetchAndSetRuleData(), process.env.REACT_APP_POLLING_INTERVAL)
        break;
      case UPLOAD_TYPE.EVENT:
        setLoading(true)
        fetchAndSetEventData().finally(() => setLoading(false))
        current.timer = setInterval(() => fetchAndSetEventData(), process.env.REACT_APP_POLLING_INTERVAL)
        break;
      case UPLOAD_TYPE.INCRPT:
        setLoading(true)
        fetchAndSetIncRptData().finally(() => setLoading(false))
        current.timer = setInterval(() => fetchAndSetIncRptData(), process.env.REACT_APP_POLLING_INTERVAL)
        break;
      case UPLOAD_TYPE.THREAT_ACTOR:
        setLoading(true)
        fetchAndSetThreatActorData().finally(() => setLoading(false))
        current.timer = setInterval(() => fetchAndSetThreatActorData(), process.env.REACT_APP_POLLING_INTERVAL)
        break;
      default: break
    }
  }

  return (
    <div className='upload-page-wrapper'>

      <Context.Provider value={{
        setLoading,
        fetchAndSetSolutionData,
        fetchAndSetTTData,
        fetchAndSetRuleData,
        fetchAndSetEventData,
        fetchAndSetIncRptData,
        fetchAndSetThreatActorData
      }}>
        <Loading className="loadingMask" visible={loading}>
          <div className='page-title'>データアップロード</div>

          <Tabs className='tab-group' type='underline' onTabChange={onTabChange}>

            <Tab label='対応策' value={UPLOAD_TYPE.SOLUTION}>
              <UploadHistoryTable data={solutionTableData} type={UPLOAD_TYPE.SOLUTION} />
            </Tab>

            <Tab label='Tactic and Technique' value={UPLOAD_TYPE.TT}>
              <UploadHistoryTable data={tTTableData} type={UPLOAD_TYPE.TT} />
            </Tab>

            <Tab label='ルール' value={UPLOAD_TYPE.RULE}>
              <UploadHistoryTable data={ruleTableData} type={UPLOAD_TYPE.RULE} />
            </Tab>

            <Tab label='地政学イベント' value={UPLOAD_TYPE.EVENT}>
              <UploadHistoryTable data={eventTableData} type={UPLOAD_TYPE.EVENT} />
            </Tab>

            <Tab label='インシデントレポート' value={UPLOAD_TYPE.INCRPT}>
              <UploadHistoryTable data={incRptTableData} type={UPLOAD_TYPE.INCRPT} />
            </Tab>

            <Tab label='脅威アクター' value={UPLOAD_TYPE.THREAT_ACTOR}>
              <UploadHistoryTable data={threatActorTableData} type={UPLOAD_TYPE.THREAT_ACTOR} />
            </Tab>
          </Tabs>

        </Loading>
      </Context.Provider>
    </div>
  );
};
